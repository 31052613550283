.Hero {
	height: 100vh;
	min-height: 860px;
	position: relative;

	.swiper {
		height: calc(100% - 92px);
	}

	.swiper-slide {
		width: 100% !important;
		margin-right: 0 !important;
	}

	.big-bg {
		height: 100%;
		background-size: cover !important;
		background-position: center;

		&--1 {
			background: linear-gradient(
					0deg,
					rgba(24, 32, 107, 0.5),
					rgba(24, 32, 107, 0.2)
				),
				url(../../assets/images/bg-1.png);
		}

		&--2 {
			background: linear-gradient(
					0deg,
					rgba(24, 32, 107, 0.5),
					rgba(24, 32, 107, 0.2)
				),
				url(../../assets/images/bg-2.png);
		}

		&--3 {
			background: linear-gradient(
					0deg,
					rgba(24, 32, 107, 0.5),
					rgba(24, 32, 107, 0.2)
				),
				url(../../assets/images/bg-3.png);
		}

		&--4 {
			background: linear-gradient(
					0deg,
					rgba(24, 32, 107, 0.5),
					rgba(24, 32, 107, 0.2)
				),
				url(../../assets/images/bg-4.png);
		}

		&--5 {
			background: linear-gradient(
					0deg,
					rgba(24, 32, 107, 0.5),
					rgba(24, 32, 107, 0.2)
				),
				url(../../assets/images/bg-5.png);
		}
	}

	.bottom-blue {
		height: 92px;
		background-color: #18206b;
		text-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 4px;
		justify-content: center;
		p {
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: #ffffff;
		}
	}

	.hero-text {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;

		display: flex;
		flex-direction: column;
		align-items: center;
		// justify-content: center;
		// gap: 0.5rem;
		padding: 20px 9.722vw;
		padding-top: 18%;

		> * {
			z-index: 1;
			color: #ffffff;
		}

		h1 {
			font-size: 64px;
			line-height: 1.2;
			text-align: center;
		}
		p {
			font-weight: 400;
			font-size: 25px;
			line-height: 96px;
			text-align: center;
			color: #ffffff;
		}
		button {
			background: rgba(24, 32, 107, 0.7);
			border: 0;
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 24px;
			color: #ffffff;
			cursor: pointer;
			padding: 0.75rem 2rem;
			transition: background 0.3s;

			margin-top: 2rem;

			&:hover {
				background: rgba(24, 32, 107, 0.7);
			}
		}
	}

	.swiper-button-next,
	.swiper-button-prev {
		// display: none;
		color: white;
		background: rgba(217, 217, 217, 0.5);
		opacity: 0.7;
		width: 50px;
		height: 50px;
		border-radius: 50%;

		&:after {
			font-size: 1.2rem;
		}
	}

	.swiper-pagination {
		display: none;
	}
}

.Section1 {
	text-align: center;
	min-height: 100vh;
	// min-height: 768px;

	.main-txt {
		padding: 72px 9.722vw;
		background: #f4f4f4;
		padding-bottom: 120px;
		h2 {
			margin-bottom: 38px;
		}

		p {
			margin-bottom: 22px;
		}
	}

	.features-testimonials {
		transform: translateY(-50%);
	}

	.features {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 4.24vw;
		align-items: flex-start;
		padding: 72px 9.722vw;
		margin-bottom: 2rem;

		p {
			font-size: 1.5rem;
			color: #000000;
		}

		.feature {
			padding: 25px;
			background-color: #ffffff;
		}
	}

	.swiper-slide {
		width: 100% !important;
		margin-right: 0 !important;
	}

	.swiper {
		max-width: 800px;
		height: 120px;
	}
	.swiper-pagination {
		margin-top: 1rem;
	}
	.testimonials {
		transform: translateY(-50%);
		background-color: #18206b;
		padding: 2rem 0;

		.testimonial {
			position: relative;
			text-align: center;
			.quotes {
				position: absolute;
				top: 30%;
				left: 5%;
				font-size: 4rem;
				font-weight: 700;
				color: rgba(217, 217, 217, 0.5);
			}

			.name {
				display: block;
				margin-top: 1rem;
				font-family: "Montserrat";
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 24px;
				color: #ffffff;
			}
		}
		h2 {
			margin-bottom: 1rem;
			color: #ffffff;
		}

		.quotes {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		p {
			// width: 80%;
			padding: 2rem 6rem;
		}
		.swiper-button-next,
		.swiper-button-prev,
		.swiper-pagination {
			display: none;
		}
	}
}

.EntranceBanner {
	text-align: center;
	height: 342px;
	background-image: url(/assets/images/entrance-bg2.png);
	padding: 2rem;

	> * {
		visibility: hidden;
	}

	* {
		color: #ffffff;
	}
	h2 {
		margin-bottom: 1.2rem;
	}

	.days-div {
		p {
			font-weight: 600;
			font-size: 25px;
			line-height: 32px;
		}
		.number {
			font-weight: 600;
			font-size: 72px;
			line-height: 96px;
			color: #fff701;
			margin-top: 2rem;
			// margin-bottom: 0.5rem;
		}
	}
}

.FacilitiesSection {
	text-align: center;
	height: 100vh;
	min-height: 768px;
	padding: 2rem 9.722vw;
	background: #f4f4f4;

	h2 {
		margin-bottom: 1rem;
	}

	.images {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 1.2rem;
		flex-wrap: wrap;
		margin-top: 56px;

		img {
			width: 31%;
		}
	}
}

.SubscribeSection {
	text-align: center;
	padding: 4rem 9.722vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	.subscribe {
		max-width: 429px;
	}

	h2 {
		margin-bottom: 1rem;
	}

	form {
		margin-top: 70px;

		input {
			padding: 22px;
			width: 100%;
			font-family: "Montserrat";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			margin-bottom: 2rem;
		}

		input[type="submit"] {
			border: 0;
			background: #18206b;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
		}
	}
}

.ExamLogos {
	padding: 1rem 9.722vw;
	background: #f4f4f4;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
}

.VideoSection {
	transform: translateY(-150px);
}

@media screen and (max-width: 1024px) {
	.Hero {
		.hero-text {
			padding: 20px;
			justify-content: center;
			padding-bottom: 45%;
			gap: 1rem;

			h1 {
				font-weight: 600;
				font-size: 25px;
				line-height: 42px;
				text-align: center;
			}
			p {
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-align: center;
				color: #ffffff;
			}

			button {
				margin-top: 200px;
			}
		}

		.swiper-button-next,
		.swiper-button-prev {
			display: none;
		}
	}

	.Section1 {
		height: unset;
		.main-txt {
			padding: 40px 20px;
			background-color: #ffffff;

			h2 {
				font-weight: 500;
			}

			p {
				color: #999999;
				font-size: 14px;
			}
		}
		.features-testimonials {
			transform: unset;

			.features {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				gap: 1rem;
				padding: 72px 20px;
			}
		}

		.testimonials {
			transform: translateY(0%);
		}

		.swiper {
			max-width: 100%;
			// height: 300px;
		}
		.swiper-button-next,
		.swiper-button-prev,
		.swiper-pagination {
			display: none;
		}
	}
	.ExamLogos {
		padding: 1rem 20px;
		min-height: 100px;
		flex-wrap: wrap;
		gap: 24px;

		img {
			height: 40px;
		}
	}

	.EntranceBanner {
		text-align: center;
		height: 342px;
		padding: 2rem 20px;

		.days-div {
			.number {
				font-size: 51px;
				line-height: 72px;
			}

			p {
				font-size: 25px;
				line-height: 24px;
			}
		}
	}

	.FacilitiesSection {
		padding: 2rem 20px;
		height: unset;

		.images {
			img {
				width: 100%;
			}
		}
	}

	.SubscribeSection {
		padding: 4rem 20px;
	}
}

@media screen and (max-width: 1024px) {
	.Hero {
		.big-bg {
			&--1 {
				background: linear-gradient(
						0deg,
						rgba(24, 32, 107, 0.5),
						rgba(24, 32, 107, 0.2)
					),
					url("../../assets/images/bg-1 small.png");
			}
			&--3 {
				background: linear-gradient(
						0deg,
						rgba(24, 32, 107, 0.5),
						rgba(24, 32, 107, 0.2)
					),
					url("../../assets/images/bg-3 small.png");
			}
		}

		.bottom-blue {
			flex-direction: column;
		}
	}

	.Section1 {
		.testimonials {
			p {
				padding: 0 2rem;
			}
			h2 {
				font-size: 18px;
			}
			.testimonial {
				text-align: center;

				.name {
					display: block;
					margin-top: 1rem;
					font-family: "Montserrat";
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 24px;
				}
			}
		}
	}
	.VideoSection {
		transform: translateY(-80px);
	}
}
