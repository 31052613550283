.MissionVision {
	padding: 4rem 9.722vw;
	background-color: #f4f4f4;

	.big-block {
		display: flex;
		flex-direction: row;
		gap: 5vw;
		margin-bottom: 2rem;

		img {
			// width: 100%;
			max-width: 370px;
		}

		&--right {
			flex-direction: row-reverse;
			text-align: right;
		}
	}

	h3 {
		margin-bottom: 0.8rem;
	}
}

.Anthems {
	padding: 4rem 9.722vw;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	// justify-content: center;
	gap: 2rem;
	flex-wrap: wrap;
	text-align: center;
	> * {
		width: 40%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 1rem;
	}

	p {
		width: 60%;
	}
}

.ValuesEthos {
	padding: 66px 9.722vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	background: #d9d9d9;

	button {
		padding: 12px 11.5px;
		font-family: "Montserrat";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: #ffffff;
		background: #18206b;
		border-radius: 5px;
		border: 0;
		cursor: pointer;
	}
}

.AboutTxt {
	padding: 72px 9.722vw;
	background: #f4f4f4;
	padding-bottom: 120px;
	text-align: center;
	h2 {
		margin-bottom: 38px;
	}

	p {
		margin-bottom: 22px;
	}

	.left {
		text-align: left;
	}
}

.Management {
	padding: 72px 9.722vw;

	h2 {
		margin-bottom: 30px;
		text-align: center;
	}

	&__list {
		display: flex;
		flex-direction: row;
		justify-content: center;
		gap: 0.7vw;
		flex-wrap: wrap;

		&__item {
			max-width: 370px;
			text-align: center;
			margin-bottom: 71px;

			.name {
				font-family: "Montserrat";
				color: #000000;
				font-weight: 600;
				font-size: 18px;
				line-height: 45px;
			}

			img {
				width: 100%;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	.MissionVision {
		padding: 20px;
		.big-block {
			flex-direction: column-reverse;
			align-items: stretch;
			text-align: center;
			margin-bottom: 40px;

			img {
				width: 100%;
				max-width: 100%;
			}

			&--right {
				text-align: center;
			}

			h3,
			p {
				text-align: left;
			}
		}
	}

	.ValuesEthos {
		flex-direction: column;
		text-align: center;
		gap: 2rem;
	}

	.Anthems {
		padding: 40px 20px;
		flex-direction: column;

		.big-block {
			width: 100%;

			p {
				width: 100%;
			}
		}
	}

	.AboutTxt {
		padding: 40px 20px;
		background-color: #ffffff;
		.left {
			text-align: center;
		}
	}

	.Management {
		padding: 40px 20px;
		&__list {
			&__item {
				max-width: 100%;
			}
		}
	}
}
