.App {
	font-family: "Montserrat";
	font-style: normal;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

p {
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #999999;
}

h1 {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 48px;
	line-height: 52px;
	color: #000000;
}

h2 {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 36px;
	line-height: 48px;
	color: #000000;
}

h3 {
	font-family: "Open Sans";
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	color: #000000;
}

@media screen and (max-width: 1024px) {
	h1 {
		font-weight: 600;
		font-size: 32px;
		line-height: 42px;
	}

	h2 {
		font-size: 18px;
		line-height: 32px;
	}
}
